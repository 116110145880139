var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Layout", [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-8 col-lg-8 col-xl-6" }, [
        _c("div", { staticClass: "card overflow-hidden" }, [
          _c("div", { staticClass: "bg-gradient-primary" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-7" }, [
                _c("div", { staticClass: "text-white p-4" }, [
                  _c("h5", { staticClass: "text-white" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("registration.header.title")) + " "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(_vm._s(_vm.$t("registration.header.description"))),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-5 align-self-center" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: {
                    src: require("@/assets/images/register-image.png"),
                    alt: "",
                  },
                }),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "card-body pt-0" },
            [
              _c("div", [
                _c("div", { staticClass: "avatar-md profile-user-wid mb-4" }, [
                  _c(
                    "span",
                    { staticClass: "avatar-title rounded-circle bg-light" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/Redem Icon.png"),
                          alt: "",
                          height: "38",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
              _c(
                "b-alert",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "success", dismissible: "" },
                  model: {
                    value: _vm.registerSuccess,
                    callback: function ($$v) {
                      _vm.registerSuccess = $$v
                    },
                    expression: "registerSuccess",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("registration.form.successMsg")))]
              ),
              _c(
                "b-alert",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.isRegisterError,
                    callback: function ($$v) {
                      _vm.isRegisterError = $$v
                    },
                    expression: "isRegisterError",
                  },
                },
                [_vm._v(_vm._s(_vm.registrationErrorMessage))]
              ),
              _c(
                "b-form",
                {
                  staticClass: "p-2",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.clickRegSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "company-group",
                        "label-for": "companyName",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "registration.form.companyName.label"
                                    )
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v("*"),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid": _vm.submit && _vm.$v.companyName.$error,
                        },
                        attrs: {
                          id: "companyName",
                          type: "text",
                          placeholder: this.$t(
                            "registration.form.companyName.placeholder"
                          ),
                        },
                        model: {
                          value: _vm.companyName,
                          callback: function ($$v) {
                            _vm.companyName = $$v
                          },
                          expression: "companyName",
                        },
                      }),
                      _vm.submit && _vm.$v.companyName.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.companyName.required
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "registration.form.companyName.requiredError"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "first-name-group",
                              "label-for": "firstName",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "registration.form.firstName.label"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v("*"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _c("b-form-input", {
                              class: {
                                "is-invalid":
                                  _vm.submit && _vm.$v.firstName.$error,
                              },
                              attrs: {
                                id: "firstName",
                                type: "text",
                                placeholder: this.$t(
                                  "registration.form.firstName.placeholder"
                                ),
                              },
                              model: {
                                value: _vm.firstName,
                                callback: function ($$v) {
                                  _vm.firstName = $$v
                                },
                                expression: "firstName",
                              },
                            }),
                            _vm.submit && _vm.$v.firstName.$error
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  !_vm.$v.firstName.required
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "registration.form.firstName.required"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "last-name-group",
                              "label-for": "lastName",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "registration.form.lastName.label"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v("*"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _c("b-form-input", {
                              class: {
                                "is-invalid":
                                  _vm.submit && _vm.$v.lastName.$error,
                              },
                              attrs: {
                                id: "lastName",
                                type: "text",
                                placeholder: this.$t(
                                  "registration.form.lastName.placeholder"
                                ),
                              },
                              model: {
                                value: _vm.lastName,
                                callback: function ($$v) {
                                  _vm.lastName = $$v
                                },
                                expression: "lastName",
                              },
                            }),
                            _vm.submit && _vm.$v.lastName.$error
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  !_vm.$v.lastName.required
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "registration.form.lastName.required"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "b-form-group",
                    {
                      attrs: { id: "email-group", "label-for": "email" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("registration.form.email.label")
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v("*"),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid": _vm.submit && _vm.$v.email.$error,
                        },
                        attrs: {
                          id: "email",
                          type: "email",
                          placeholder: this.$t(
                            "registration.form.email.placeholder"
                          ),
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                      _vm.submit && _vm.$v.email.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.email.required
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "registration.form.email.requiredErrorMsg"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            !_vm.$v.email.email
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "registration.form.email.notAnEmailErrorMsg"
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "password-group",
                              "label-for": "password",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "registration.form.password.label"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v("*"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _c("b-form-input", {
                              class: {
                                "is-invalid":
                                  _vm.submit && _vm.$v.password.$error,
                              },
                              attrs: {
                                id: "password",
                                type: "password",
                                placeholder: this.$t(
                                  "registration.form.password.placeholder"
                                ),
                              },
                              model: {
                                value: _vm.password,
                                callback: function ($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password",
                              },
                            }),
                            _vm.submit && _vm.$v.password.$error
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  !_vm.$v.password.required
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "registration.form.password.requiredErrorMsg"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  !_vm.$v.password.minLength
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "registration.form.password.minLengthErrorMsg"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "password-group",
                              "label-for": "confirmPassword",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "registration.form.confirmPassword.label"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v("*"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _c("b-form-input", {
                              class: {
                                "is-invalid":
                                  _vm.submit && _vm.$v.confirmPassword.$error,
                              },
                              attrs: {
                                id: "confirmPassword",
                                type: "password",
                                placeholder: this.$t(
                                  "registration.form.confirmPassword.placeholder"
                                ),
                              },
                              model: {
                                value: _vm.confirmPassword,
                                callback: function ($$v) {
                                  _vm.confirmPassword = $$v
                                },
                                expression: "confirmPassword",
                              },
                            }),
                            _vm.submit && _vm.$v.confirmPassword.$error
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  !_vm.$v.confirmPassword.required
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "registration.form.confirmPassword.requiredErrorMsg"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  !_vm.$v.confirmPassword.sameAs
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "registration.form.confirmPassword.notMatchErrorMsg"
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "custom-control custom-checkbox mt-4" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newsLetterAccept,
                            expression: "newsLetterAccept",
                          },
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          id: "customControlForNewsLetterInline",
                          type: "checkbox",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.newsLetterAccept)
                            ? _vm._i(_vm.newsLetterAccept, null) > -1
                            : _vm.newsLetterAccept,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.newsLetterAccept,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.newsLetterAccept = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.newsLetterAccept = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.newsLetterAccept = $$c
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "customControlForNewsLetterInline" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("registration.form.newsLetter.text")
                              ) +
                              " "
                          ),
                          _c("i", {
                            staticClass: "bx bxs-info-circle ml-1",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.showMoreNewsLetter()
                              },
                            },
                          }),
                        ]
                      ),
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "registration.form.newsLetter.supportText.text1"
                              )
                            ) +
                            " "
                        ),
                        _c("b", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "registration.form.newsLetter.supportText.text2"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "registration.form.newsLetter.supportText.text3"
                              )
                            ) +
                            " "
                        ),
                        _c("b", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "registration.form.newsLetter.supportText.text4"
                                )
                              )
                          ),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "registration.form.newsLetter.supportText.text5"
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm.isDisplayExpandNewsLetter
                        ? _c("p", { staticClass: "mt-3" }, [
                            _c("small", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "registration.form.newsLetter.expandText"
                                    )
                                  ) +
                                  " "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://redem.io/privacy-policy",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Privacy Policy")]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "custom-control custom-checkbox mt-4" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.termsAccept,
                            expression: "termsAccept",
                          },
                        ],
                        staticClass: "custom-control-input",
                        class: {
                          "is-invalid": _vm.submit && _vm.$v.termsAccept.$error,
                        },
                        attrs: { id: "customControlInline", type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.termsAccept)
                            ? _vm._i(_vm.termsAccept, null) > -1
                            : _vm.termsAccept,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.termsAccept,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.termsAccept = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.termsAccept = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.termsAccept = $$c
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "customControlInline" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("registration.form.terms.text1")) +
                              " "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://redem.io/terms-and-conditions",
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("registration.form.terms.text2"))
                              ),
                            ]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("registration.form.terms.text3"))
                          ),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v("*"),
                          ]),
                          _c("i", {
                            staticClass: "bx bxs-info-circle ml-1",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.showMoreTerms()
                              },
                            },
                          }),
                        ]
                      ),
                      _vm.isDisplayExpandTerms
                        ? _c("p", [
                            _c("small", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("registration.form.terms.expandText")
                                  ) +
                                  " "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-block",
                          attrs: { type: "submit", variant: "success" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("registration.form.registerButton"))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "mt-5 text-center" }, [
          _c(
            "p",
            [
              _vm._v(" " + _vm._s(_vm.$t("registration.footer.text1")) + " "),
              _c(
                "router-link",
                {
                  staticClass: "font-weight-medium text-info",
                  attrs: { tag: "a", to: "/login" },
                },
                [_vm._v(_vm._s(_vm.$t("registration.footer.text2")))]
              ),
            ],
            1
          ),
          _c("p", [
            _vm._v(
              " © " +
                _vm._s(new Date().getFullYear()) +
                " Redem GmbH | made with "
            ),
            _c("i", { staticClass: "mdi mdi-heart text-danger" }),
            _vm._v(" in Austria "),
          ]),
        ]),
        _c("div", { staticClass: "mt-3 text-center" }, [
          _c(
            "span",
            {
              staticClass: "mr-2",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.setLanguage(_vm.languages[0].language)
                },
              },
            },
            [
              _c("img", {
                staticClass: "mr-1",
                attrs: { src: _vm.languages[0].flag, height: "10" },
              }),
              _vm._v(" " + _vm._s(_vm.languages[0].title) + " "),
            ]
          ),
          _vm._v(" • "),
          _c(
            "span",
            {
              staticClass: "ml-2",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.setLanguage(_vm.languages[1].language)
                },
              },
            },
            [
              _c("img", {
                staticClass: "mr-1",
                attrs: { src: _vm.languages[1].flag, height: "10" },
              }),
              _vm._v(" " + _vm._s(_vm.languages[1].title) + " "),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }